<template>
    <v-dialog scrollable light v-model="licenseDialog" persistent max-width="1100">
        <v-card>
            <v-card-title style="border-bottom: 1px solid #ccc;" class="headline px-2">
                <v-app-bar flat color="rgba(0, 0, 0, 0)">
                    <v-toolbar-title class="text-h5 pl-0">
                        Detalhes do lote
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- <v-fab-transition> -->
                    <v-btn @click="toogleLicenseDialog" class="mt-7" color="red" fab icon dark x-small absolute top
                        right>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <!-- </v-fab-transition> -->
                </v-app-bar>
            </v-card-title>
            <v-card-text>
                <v-card flat>
                    <v-card-text class="pa-0">
                        <h3 class="mt-8 text-h6 font-weight-medium pl-1">Lista de licenças</h3>
                        <v-data-table class="elevation-1 mt-5" :headers="headers" :items="licenses" :items-per-page="5">
                            <template v-slot:item.status="{ item }">
                                <div class="px-3">
                                    <div class="mt-3"><v-icon :color="setStatusIcon(item.status)[1]">{{
        setStatusIcon(item.status)[0] }}</v-icon></div>
                                    <div :class="['mb-3', setStatusIcon(item.status)[1] + '--text']">{{ item.status ?
        item.status : "Aguardando" }}</div>
                                </div>
                            </template>
                            <template v-slot:item.requesting_company="{ item }">
                                <div>{{ item.requesting_company }}</div>
                                <div><small>{{ item.tenant }}</small></div>
                            </template>
                            <template v-slot:item.requesting_email="{ item }">
                                {{ item.requesting_email }}
                            </template>
                            <template v-slot:item.licence.name="{ item }">
                                <div>{{ item.licence.name }}</div>
                                <div><small>{{ item.operation_type }}</small></div>
                            </template>
                            <template v-slot:item.licence.price="{ item }">
                                R$ {{ item.licence.price | formatPrice }}
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="toogleLicenseDialog">
                    Fechar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import MyFilters from "../../../../filters/genericFilters";
export default {
    methods: {
        setStatusIcon(status) {
            switch (status) {
                case "Liberado":
                    return ["playlist_add_check", "green"];
                case "Em Analise":
                    return ["rotate_left", "warning"];
                case "Reprovado":
                    return ["remove_circle_outline", "red"];
                default:
                    return ["hourglass_top", "blue"];
            }
        }
    },
    data() {
        return {
            headers: [
                {
                    text: "Status",
                    value: "status",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "Empresa",
                    value: "requesting_company",
                    align: "start",
                    sortable: false,
                },
                {
                    text: "E-mail",
                    value: "requesting_email",
                    align: "start",
                    sortable: false,
                },
                {
                    text: "Licença / Operação",
                    value: "licence.name",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "N.º da ordem",
                    value: "order",
                    align: "center",
                    sortable: false,
                },
                // {
                //     text: "Operação",
                //     value: "operation_type",
                //     align: "start",
                //     sortable: false,
                // },
                {
                    text: "Qtd. de licenças",
                    value: "qtd",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "Valor",
                    value: "licence.price",
                    align: "end",
                    sortable: false,
                }
            ]
        }
    },
    props: {
        licenses: Array,
        licenseDialog: Boolean,
        toogleLicenseDialog: Function
    },
}
</script>

<style lang="scss" scoped></style>