<template>
    <div>
        <v-row class="pt-5 pb-2">
            <v-col class="pl-5 pt-0 pb-3 ml-2" cols="12" md="5">
                <v-select @change="getHistoryLogins" persistent-hint
                    hint="Selecione um intervalo de tempo para visualizar o histórico de logins" label="Intervalo de tempo"
                    item-text="text" item-value="val" clearable v-model="qtdDays" :items="intervalDays"></v-select>
            </v-col>
        </v-row>
        <v-data-table :headers="headers" :items="historyLogins" :items-per-page="5" class="elevation-0 px-5">
            <template v-slot:item.status="{ item }">
                <div class="maxw-350">
                    <v-icon class="mb-1 mr-2" small v-if="item.autentication" color="primary" dark>
                        mdi-account-key
                    </v-icon>
                    <v-icon class="mb-1 mr-2" small v-else color="red" dark>
                        mdi-account-alert
                    </v-icon>
                    <span>{{ item.status }}</span>
                    <div><small><b>Mensagem: </b> {{ item.message ? item.message : 'Não há mensagem' }}</small></div>
                </div>
            </template>
            <template v-slot:item.ip="{ item }"> <span
                    v-html="item.ip ? item.ip : '<small>Não detectado</small>'"></span></template>
            <template v-slot:item.device="{ item }">
                <div class="maxw-350">
                    <small>{{ item.device ? item.device : 'Não detectado' }}</small>
                </div>
            </template>
            <template v-slot:item.event_date="{ item }">
                <span>{{ item.event_date.split(" ")[0] | formatDate }}</span> - <span> {{ item.event_date.split(" ")[1]
                }}</span>
            </template>
        </v-data-table>
    </div>
</template>
  
<script>
import MyFilters from "../../../filters/genericFilters";
export default {
    watch: {
        idSubscriber(v) {
            if (!v) {
                this.qtdDays = ""
                this.historyLogins = []
            }
        }
    },
    props: {
        // historyLogins: Array,
        idSubscriber: Number,
    },
    methods: {
        getHistoryLogins() {
            //   console.log("moredet", data.id);
            var obj = {
                url: "/api/v1/subscriptionAPI/autentication/history/getAllAutenticationSubscriber",
                query: {
                    subscriber_id: this.idSubscriber,
                    quantity_days: this.qtdDays
                },
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.historyLogins = [...response.data]
            });
        },
    },
    data() {
        return {
            qtdDays: "",
            historyLogins: [],
            intervalDays: [
                {
                    val: 30,
                    text: "Últimos 30 dias"
                },
                {
                    val: 60,
                    text: "Últimos 60 dias"
                },
                {
                    val: 90,
                    text: "Últimos 90 dias"
                },
                {
                    val: 120,
                    text: "Últimos 120 dias"
                },
            ],
            // "id": 24,
            // "name": "Leandro Torres",
            // "email": "leandrotorres1970@gmail.com",
            // "event_date": "2023-06-20 12:06:07",
            // "status": "Usuario autenticado",
            // "subscriber_id": 44,
            // "partner_id": 74,
            // "created_at": "2023-06-20T15:47:07.000000Z",
            // "updated_at": "2023-06-20T15:47:07.000000Z"
            headers: [
                {
                    text: "Status",
                    align: "start",
                    sortable: false,
                    value: "status",
                },
                {
                    text: "IP",
                    align: "center",
                    sortable: false,
                    value: "ip",
                },
                {
                    text: "Devices",
                    align: "start",
                    sortable: false,
                    value: "device",
                },
                {
                    text: "Data do login",
                    align: "start",
                    sortable: false,
                    value: "event_date",
                },
            ],
        };
    },
};
</script>
  
<style>

</style>