var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-15",staticStyle:{"max-width":"1600px","margin":"auto","position":"relative"}},[_c('v-row',{staticClass:"px-1 pt-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-2 pr-3 mt-12 mb-8",attrs:{"cols":"12","md":"12"}},[_c('h2',{staticClass:"listTitles font-weight-medium"},[_vm._v("Minha lista de checkpoints")])]),_c('v-col',{staticClass:"pl-2 pr-3",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"prepend-icon":"filter_alt","disabled":_vm.$store.state.clientPermissions.subscriberJustView,"label":"Número do lote"},model:{value:(_vm.batch),callback:function ($$v) {_vm.batch=$$v},expression:"batch"}})],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"2"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"x-small":"","fab":"","color":"primary"},on:{"click":function($event){return _vm.getCheckpointsByFilters(true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("search")])],1)]}}])},[_c('span',[_vm._v("Pesquisar")])])],1),_c('v-col',{staticClass:"pt-0 pl-2 pr-3 mt-3 text-right",attrs:{"cols":"12","md":"12"}},[_c('v-btn',{staticClass:"ma-2 py-2",attrs:{"color":"success"},on:{"click":_vm.toogleCheckpointDialog}},[_c('span',{staticClass:"noIconPlus"},[_vm._v("+")]),_vm._v(" Criar checkpoint ")])],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{class:[_vm.checkpoints ? 'hideCol' : '', 'elevation-1 thBorderBold'],attrs:{"id":"checkpointTable","headers":_vm.headers,"items":_vm.checkpoints,"items-per-page":5,"no-data-text":"Clique acima no botão pesquisar"},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{class:[_vm.setStatusIcon(item.status)[1] + '--text']},[_vm._v(_vm._s(item.status)),_c('v-icon',{staticClass:"ml-2",attrs:{"size":"21","color":_vm.setStatusIcon(item.status)[1]}},[_vm._v(_vm._s(_vm.setStatusIcon(item.status)[0]))])],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.issue_date))+" ")]}},{key:"item.request_items",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.request_items.length)+" licença(s) ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-btn',{attrs:{"color":"primaryNew","outlined":"","rounded":"","x-small":""},on:{"click":function($event){return _vm.showBatchDetails(item)}}},[_vm._v(" + Detalhes ")]),(item.worksheet_process_log.length)?_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primaryNew","outlined":"","rounded":"","x-small":""},on:{"click":function($event){return _vm.showLogDetails(item)}}},[_vm._v(" Visualizar logs ")]):_vm._e()],1)]}}])})],1)],1),_c('CheckpointDialog',{attrs:{"toogleCheckpointDialog":_vm.toogleCheckpointDialog,"checkpointDialog":_vm.checkpointDialog}}),_c('ShowLicensesDialog',{attrs:{"toogleLicenseDialog":_vm.toogleLicenseDialog,"licenseDialog":_vm.licenseDialog,"licenses":_vm.licenses}}),_c('LogDetails',{attrs:{"toogleLogDialog":_vm.toogleLogDialog,"logDialog":_vm.logDialog,"stringMessage":_vm.stringMessage,"jsonMessage":_vm.jsonMessage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }