<template>
    <v-row justify="center">
        <v-dialog light v-model="licenseFormDialog" persistent max-width="700">
            <v-card>
                <v-card-title style="border-bottom: 1px solid #ccc;" class="headline px-2 pb-0">
                    <v-app-bar flat color="rgba(0, 0, 0, 0)">
                        <v-toolbar-title class="text-h5 pl-0" style="height: 45px">
                            Nova solicitação
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <!-- <v-fab-transition> -->
                        <v-btn @click="toogleLicenseFormDialog" class="mt-6" color="red" fab icon dark x-small absolute top
                            right>
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <!-- </v-fab-transition> -->
                    </v-app-bar>
                </v-card-title>
                <v-card-text>
                    <v-form ref="licenseForm" v-model="valid">
                        <v-container class="mt-2">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-text-field v-model="formData.requesting_company"
                                        :rules="$store.state.formRules.required" label="Empresa" required></v-text-field>
                                </v-col>
                                <!-- <v-col cols="12" md="12">
                                    <v-text-field v-model="formData.tenant" :rules="$store.state.formRules.required"
                                        label="Nome fantasia (Tenant)" required></v-text-field>
                                </v-col> -->
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="formData.requesting_email"
                                        :rules="$store.state.formRules.emailRules" label="Email" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <div>
                                        <v-text-field-simplemask v-model="formData.document" v-bind:label="`CPF/CNPJ`"
                                            v-bind:properties="{
                                                rules: [
                                                    $store.state.formRules.cpfCnpjRules[0](
                                                        formData.document ? formData.document : ''
                                                    ),
                                                ],
                                                prefix: '',
                                                suffix: '',
                                                readonly: false,
                                                disabled: $store.state.clientPermissions.subscriberJustView,
                                                outlined: false,
                                                clearable: false,
                                                placeholder: '',
                                            }" v-bind:options="{
    inputMask:
        (formData.document
            ? formData.document.length
            : '') <= 11
            ? '###.###.###-########'
            : '##.###.###/####-##',
    outputMask: '##############',
    empty: null,
    applyAfter: false,
    alphanumeric: true,
    lowerCase: false,
}" />
                                    </div>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="formData.qtd" :rules="$store.state.formRules.required"
                                        label="Quantidade de licenças" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-select :items="operationsList" v-model="formData.operation_type"
                                        label="Operação"></v-select>

                                    <!-- <v-text-field v-model="formData.operation_type" :rules="$store.state.formRules.required"
                                        label="Operação" required></v-text-field> -->
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-select item-text="name" item-value="id" :items="typeLicenseList"
                                        v-model="formData.licence_id" label="Tipo de licença"></v-select>

                                    <!-- <v-text-field v-model="formData.operation_type" :rules="$store.state.formRules.required"
                                        label="Operação" required></v-text-field> -->
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field maxlength="8" v-model="formData.order"
                                        :rules="$store.state.formRules.required" label="Ordem" required></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>

                    <!-- <v-tab-item>
                <PermissionsList :accessPermissions="formData.access_permission" :licenseFormDialog="licenseFormDialog"
                  ref="permissionsForm" :services="services" :license_id="formData.id" />
              </v-tab-item> -->

                </v-card-text>
                <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="toogleLicenseFormDialog">
                        Fechar
                    </v-btn>
                    <v-btn color="green" text @click="saveLicense"> aplicar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
  
<script>
const resetLicenseForm = {
    order: "",
    qtd: "",
    operation_type: "",
    requesting_company: "",
    // tenant: "",
    document: "",
    requesting_email: "",
    licence_id: ""
};
//   import PermissionsList from "./PermissionsList.vue";
import MyFilters from "../../../../filters/genericFilters";
export default {
    methods: {
        getTypeLicenseList() {
            var obj = {
                url: "/api/checkpoint/licences/getAll",
                query: {},
                method: "get",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                if (!response) {
                    return;
                }
                this.typeLicenseList = [...response.data]
            })
        },
        saveLicense() {
            if (!this.$refs.licenseForm.validate()) {
                return;
            }
            this.aplyLicense(this.formData)
            this.toogleLicenseFormDialog()
        },
    },
    watch: {
        licenseFormDialog(v) {
            // console.log("frm license");
            if (!v) {
                this.$refs.licenseForm.resetValidation();
                this.formData = { ...resetLicenseForm };
            } else {
                this.tab = 0
            }
        },
    },
    props: {
        // services: Array,
        aplyLicense: Function,
        editLicenseData: Object,
        partnerId: Number,
        updateLicenseList: Function,
        licenseFormDialog: Boolean,
        toogleLicenseFormDialog: Function,
    },
    data() {
        return {
            typeLicenseList: [],
            operationsList: ["contratação"],
            tab: null,
            valid: true,
            formData: { ...resetLicenseForm },
        };
    },
    mounted() {
        this.getTypeLicenseList();
    },
    components: {
        //   PermissionsList,
    },
};
</script>
  
<style></style>