<template>
  <div>
    <v-row class="py-5">
      <v-col class="px-15" cols="12" md="12"
        ><p class="mb-0">
          O Histórico da subscrição registra toda e qualquer alteração que tenha
          ocorrido. Aqui você acompanha o ciclo de vida de uma subscrição por completo, desde a data de sua criação com
          todos seus "upgrades" ou "downgrades".
        </p></v-col
      >
    </v-row>
    <v-data-table
      :headers="headers"
      :items="lastHistory"
      :items-per-page="5"
      class="elevation-1 px-10"
    >
      <template v-slot:item.event_date="{ item }">
        {{ item.event_date | formatDate }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import MyFilters from "../../../filters/genericFilters";
export default {
  props: {
    lastHistory: Array,
  },
  data() {
    return {
      headers: [
        {
          text: "Descrição da alteração",
          align: "start",
          sortable: false,
          value: "action",
        },
        {
          text: "Data da alteração",
          align: "start",
          sortable: false,
          value: "event_date",
        },
      ],
    };
  },
};
</script>

<style>
</style>