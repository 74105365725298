var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","light":"","persistent":"","max-width":"1100"},model:{value:(_vm.licenseDialog),callback:function ($$v) {_vm.licenseDialog=$$v},expression:"licenseDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline px-2",staticStyle:{"border-bottom":"1px solid #ccc"}},[_c('v-app-bar',{attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h5 pl-0"},[_vm._v(" Detalhes do lote ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-7",attrs:{"color":"red","fab":"","icon":"","dark":"","x-small":"","absolute":"","top":"","right":""},on:{"click":_vm.toogleLicenseDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('h3',{staticClass:"mt-8 text-h6 font-weight-medium pl-1"},[_vm._v("Lista de licenças")]),_c('v-data-table',{staticClass:"elevation-1 mt-5",attrs:{"headers":_vm.headers,"items":_vm.licenses,"items-per-page":5},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"px-3"},[_c('div',{staticClass:"mt-3"},[_c('v-icon',{attrs:{"color":_vm.setStatusIcon(item.status)[1]}},[_vm._v(_vm._s(_vm.setStatusIcon(item.status)[0]))])],1),_c('div',{class:['mb-3', _vm.setStatusIcon(item.status)[1] + '--text']},[_vm._v(_vm._s(item.status ? item.status : "Aguardando"))])])]}},{key:"item.requesting_company",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.requesting_company))]),_c('div',[_c('small',[_vm._v(_vm._s(item.tenant))])])]}},{key:"item.requesting_email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.requesting_email)+" ")]}},{key:"item.licence.name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.licence.name))]),_c('div',[_c('small',[_vm._v(_vm._s(item.operation_type))])])]}},{key:"item.licence.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" R$ "+_vm._s(_vm._f("formatPrice")(item.licence.price))+" ")]}}])})],1)],1)],1),_c('v-card-actions',{staticClass:"px-0 mx-3",staticStyle:{"border-top":"1px solid rgb(220, 220, 220)"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.toogleLicenseDialog}},[_vm._v(" Fechar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }