var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 mt-6 mb-4",attrs:{"footer-props":{
        'items-per-page-text': 'Provedores por página',
    },"loading":false,"headers":_vm.headers,"items":_vm.licenses,"items-per-page":5},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-xs-center",staticStyle:{"min-height":"90px","padding-top":"35px"}},[_vm._v(" Não há dados ")])]},proxy:true},{key:"item.requesting_company",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.requesting_company))]),_c('div',[_c('small',[_vm._v(_vm._s(item.tenant))])])]}},{key:"item.document",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatCnpjCpf")(item.document)))]),_c('div',[_c('small',[_vm._v(_vm._s(item.requesting_email))])])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btMicro px-1",attrs:{"x-small":"","color":"red","dark":""},on:{"click":function($event){_vm.confirmDelLicense(item, _vm.licenses.indexOf(item))}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","x-small":""}},[_vm._v(" mdi-delete-forever ")])],1)]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v("Excluir licença")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }