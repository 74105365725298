<template>
    <v-row justify="center">
        <v-dialog scrollable light v-model="subscriberDilog" persistent max-width="1280">
            <v-card>
                <v-card-title style="border-bottom: 1px solid #ccc;" class="headline px-2">
                    <v-app-bar flat color="rgba(0, 0, 0, 0)">
                        <v-toolbar-title class="text-h5 pl-0">
                            {{ formData.id ? "Editar subscrição" : "Nova subscrição" }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <!-- <v-fab-transition> -->
                        <v-btn @click="toogleSubscriberDilog" class="mt-7" color="red" fab icon dark x-small absolute
                            top right>
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <!-- </v-fab-transition> -->
                    </v-app-bar>
                </v-card-title>
                <v-card-text>
                    <v-card flat>
                        <v-card-text class="pa-0">
                            <div class="">
                                <v-tabs color="primaryNew" id="subscriberFormTab" v-model="tab"
                                    style="border-bottom: 1px solid #eee">
                                    <!-- <v-tabs-slider color="white"></v-tabs-slider> -->
                                    <v-tab> Dados do usuário </v-tab>
                                    <v-tab> Dados da subscrição </v-tab>
                                    <v-tab :disabled="this.formData.id ? false : true">
                                        Pacotes adicionais
                                    </v-tab>
                                    <v-tab :disabled="this.formData.id ? false : true">
                                        Histórico da subscrição
                                    </v-tab>
                                    <!-- <v-tab :disabled="this.formData.id ? false : true">
                      Histórico de logins
                    </v-tab> -->
                                </v-tabs>
                                <v-tabs-items v-model="tab">
                                    <v-tab-item>
                                        <v-form ref="userSubscriber" v-model="validTab1">
                                            <v-container>
                                                <v-row class="pt-3">
                                                    <v-col cols="12" md="6">
                                                        <v-text-field
                                                            :disabled="$store.state.clientPermissions.subscriberJustView"
                                                            v-model="formData.name"
                                                            :rules="$store.state.formRules.required"
                                                            label="Nome do usuário" required></v-text-field>
                                                    </v-col>

                                                    <v-col cols="12" md="6">
                                                        <div>
                                                            <v-text-field-simplemask v-model="formData.document"
                                                                v-bind:label="`CPF/CNPJ`" v-bind:properties="{
                                                                    rules: [
                                                                        $store.state.formRules.cpfCnpjRules[0](
                                                                            formData.document ? formData.document : ''
                                                                        ),
                                                                    ],
                                                                    prefix: '',
                                                                    suffix: '',
                                                                    readonly: false,
                                                                    disabled: $store.state.clientPermissions.subscriberJustView,
                                                                    outlined: false,
                                                                    clearable: false,
                                                                    placeholder: '',
                                                                }" v-bind:options="{
                                                                    inputMask:
                                                                        (formData.document
                                                                            ? formData.document.length
                                                                            : '') <= 11
                                                                            ? '###.###.###-########'
                                                                            : '##.###.###/####-##',
                                                                    outputMask: '##############',
                                                                    empty: null,
                                                                    applyAfter: false,
                                                                    alphanumeric: true,
                                                                    lowerCase: false,
                                                                }" />
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <div>
                                                            <!-- rules: [
                                      $store.state.formRules.cepRules[0](
                                        formData.zip_code
                                      ),
                                    ], -->
                                                            <v-text-field-simplemask v-model="formData.zip_code"
                                                                v-bind:label="`CEP`" v-bind:properties="{
                                                                    prefix: '',
                                                                    suffix: '',
                                                                    readonly: false,
                                                                    disabled: $store.state.clientPermissions.subscriberJustView,
                                                                    outlined: false,
                                                                    clearable: false,
                                                                    placeholder: '',
                                                                }" v-bind:options="{
                                                                    inputMask: '#####-###',
                                                                    outputMask: '########',
                                                                    empty: null,
                                                                    applyAfter: false,
                                                                    alphanumeric: true,
                                                                    lowerCase: false,
                                                                }" />
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12" md="6">
                                                        <!-- :rules="$store.state.formRules.required" -->
                                                        <v-text-field
                                                            :disabled="$store.state.clientPermissions.subscriberJustView"
                                                            v-model="formData.address" label="Endereço"
                                                            required></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <!-- :rules="$store.state.formRules.numberRules" -->
                                                        <v-text-field
                                                            :disabled="$store.state.clientPermissions.subscriberJustView"
                                                            v-model="formData.number" label="Número"
                                                            required></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="6">
                                                        <v-text-field
                                                            :disabled="$store.state.clientPermissions.subscriberJustView"
                                                            v-model="formData.complement" label="Complemento"
                                                            required></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="6">
                                                        <!-- :rules="$store.state.formRules.required" -->
                                                        <v-text-field
                                                            :disabled="$store.state.clientPermissions.subscriberJustView"
                                                            v-model="formData.neighborhood" label="Bairro"
                                                            required></v-text-field>
                                                    </v-col>
                                                    <v-col class="" cols="12" md="2">
                                                        <!-- :hint="`${select.state}, ${select.abbr}`" -->
                                                        <!-- :rules="$store.state.formRules.required" -->
                                                        <v-select
                                                            :disabled="$store.state.clientPermissions.subscriberJustView"
                                                            light v-model="formData.state"
                                                            :items="$store.state.ufBrasil.UF" item-text="state"
                                                            item-value="state" label="UF"></v-select>
                                                    </v-col>
                                                    <v-col cols="12" md="6">
                                                        <!-- :rules="$store.state.formRules.required" -->
                                                        <v-text-field
                                                            :disabled="$store.state.clientPermissions.subscriberJustView"
                                                            v-model="formData.city" label="Cidade"
                                                            required></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="4">
                                                        <div>
                                                            <v-text-field-simplemask v-model="formData.phone"
                                                                v-bind:label="'Telefone'" v-bind:properties="{
                                                                    rules: [
                                                                        $store.state.formRules.phoneRules[0](
                                                                            formData.phone
                                                                        ),
                                                                    ],
                                                                    prefix: '',
                                                                    suffix: '',
                                                                    readonly: false,
                                                                    disabled: $store.state.clientPermissions.subscriberJustView,
                                                                    outlined: false,
                                                                    clearable: false,
                                                                    placeholder: '',
                                                                }" v-bind:options="{
                                                                    inputMask:
                                                                        (formData.phone
                                                                            ? formData.phone.length
                                                                            : '') < 11
                                                                            ? '(##) ####-#####'
                                                                            : '(##) # ####-####',
                                                                    outputMask: '############',
                                                                    empty: null,
                                                                    applyAfter: false,
                                                                    alphanumeric: true,
                                                                    lowerCase: false,
                                                                }" />
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-form>
                                    </v-tab-item>
                                    <v-tab-item>
                                        <v-form ref="dataSubscriber" v-model="validTab2">
                                            <v-container>
                                                <v-row class="pt-3">
                                                    <v-col class="pr-2" cols="12" md="12">
                                                        <p class="mb-0">
                                                            Para adicionar novos pacotes à assinatura é
                                                            necessário primeiramente criar uma assinatura.
                                                            <br />
                                                            Clique em salvar e a opção "PACOTES ADICIONAIS"
                                                            será liberada automáticamente para você adicionar
                                                            serviços como HBOmax, Telecine, Brasileirão etc...
                                                        </p>
                                                    </v-col>
                                                    <v-col style="opacity: .6;" cols="12" md="6">
                                                        <!-- :disabled="$store.state.clientPermissions.subscriberJustView" -->
                                                        <v-select readonly @change="getBasicPacks" light
                                                            :rules="$store.state.formRules.required"
                                                            v-model="formData.contract_id" :items="contractsList"
                                                            item-value="id" item-text="service.name"
                                                            label="Selecione um App"></v-select>
                                                    </v-col>
                                                    <v-col class="" cols="12" md="6">
                                                        <!-- :disabled="formData.contract_id ? false : true" -->
                                                        <v-select @change="warningUpadateBasicPack"
                                                            :disabled="$store.state.clientPermissions.subscriberJustView"
                                                            :items="basicPacks" item-value="urn_prefix" item-text="name"
                                                            v-model="formData.basic_package"
                                                            label="Selecione um pacote básico"></v-select>
                                                    </v-col>
                                                    <v-col class="" cols="12" md="6">
                                                        <v-text-field
                                                            :disabled="$store.state.clientPermissions.subscriberJustView"
                                                            v-model="formData.email" label="E-mail"
                                                            :rules="$store.state.formRules.emailRules"></v-text-field>
                                                    </v-col>
                                                    <v-col class="pt-0 mb-10 pr-2" cols="12" md="6">
                                                        <p class="mb-0 pl-2">
                                                            Por motivos de segurança a senha do assinante será
                                                            gerada automáticamente e enviada ao e-mail
                                                            informado.
                                                            <br />
                                                            A alteração da senha ocorrerá no aplicativo selecionado.
                                                        </p>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-form>
                                    </v-tab-item>
                                    <v-tab-item :disabled="this.formData.id ? false : true">
                                        <v-row class="px-3">
                                            <v-col class="pb-0 pr-2 d-flex flex-column" cols="12" md="5">
                                                <h3 class="mt-8 text-subtitle-1 font-weight-medium">Siga as instruções
                                                    abaixo</h3>
                                                <p class="mt-3">
                                                    Selecione abaixo os pacotes adicionais que deseja
                                                    incluir na assinatura e clique no botão "ADICIONAR
                                                    PACOTE". Caso deseje remover um pacote basta clicar no botão
                                                    remover pacote e confirmar a ação.
                                                </p>

                                                <v-form v-show="unContracteds.length ? true : false" ref="miniFormPacs"
                                                    style="width: 100%">
                                                    <v-row style="background-color: #f4f5ff; border-radius: 5px;"
                                                        class="px-3 pb-2 mt-2 mb-4">
                                                        <v-col class="px-0 pt-0" cols="12" md="12" id="comboPacs">
                                                            <v-combobox
                                                                :disabled="$store.state.clientPermissions.subscriberJustView"
                                                                clearable return-object :items="unContracteds"
                                                                v-model="formData.urn_id" :item-text="(item) =>
                                                                    `${item.name} R$ ${formatPrice(item.price)}`
                                                                    " item-value="id"
                                                                label="Selecione um pacote adicional">
                                                            </v-combobox>
                                                        </v-col>
                                                        <v-col class="pt-0" cols="12" md="12">
                                                            <v-btn block
                                                                :disabled="$store.state.clientPermissions.subscriberJustView"
                                                                @click="confirmAddNewPac(formData.urn_id)" class="mt-0"
                                                                elevation="1" small
                                                                color="success"><v-spacer></v-spacer><v-icon small left>
                                                                    add
                                                                </v-icon>Adicionar pacote <v-spacer></v-spacer><v-icon
                                                                    small right> east
                                                                </v-icon></v-btn></v-col>
                                                    </v-row>
                                                </v-form>
                                            </v-col>
                                            <v-col class="pb-0 pr-2 d-flex flex-column align-center justify-center"
                                                cols="12" md="1"><v-icon color="blue-grey lighten-4"
                                                    large>double_arrow</v-icon></v-col>
                                            <v-col class="pb-0 pr-2" cols="12" md="6">
                                                <h6 class="text-h6 mt-7 mb-4">{{ !unContracteds.length ? 'Carregando...' :
                                                    'Pacotes adicionais à subscrição' }} </h6>
                                                <v-progress-linear v-if="!unContracteds.length ? true : false"
                                                    color="deep-purple accent-4" indeterminate rounded
                                                    height="3"></v-progress-linear>
                                                <ExtraPacsList :contracteds="contracteds" :updatePacs="updatePacs"
                                                    :confirmRemovePac="confirmRemovePac" />
                                            </v-col>
                                        </v-row>
                                    </v-tab-item>
                                    <v-tab-item :disabled="this.formData.id ? false : true">
                                        <HistoryList :lastHistory="lastHistory" />
                                    </v-tab-item>
                                    <!-- <v-tab-item :disabled="this.formData.id ? false : true">
                      <v-container class="px-5" style="max-width: 100% !important;">
                        <HistoryLogins :idSubscriber="formData.id ? formData.id : null" />
                      </v-container>
                    </v-tab-item> -->
                                </v-tabs-items>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-card-text>
                <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="toogleSubscriberDilog">
                        Fechar
                    </v-btn>
                    <v-btn v-if="!$store.state.clientPermissions.subscriberJustView" color="green" text
                        @click="updateBasicPack ? doUpdateBasicPack() : saveSubscriber()">
                        Salvar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
const resetSubscriberForm = {
    partner_subscriber_id: "",
    extraPacs: [],
    password: "",
    basic_package: "",
    confirmPwd: "",
    urn_id: "",
    email: "",
    name: "",
    document: "",
    phone: "",
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
    zip_code: "",
    contract_id: "",
};
import MyFilters from "../../../filters/genericFilters";
import ExtraPacsList from "./ExtraPacsList";
import HistoryList from "./HistoryList";
import HistoryLogins from "./HistoryLogins";
export default {
    mounted() {
        // this.getBasicPacks();
    },
    methods: {
        async removeBasicPack() {
            var obj = {
                url: "/api/v1/subscriptionAPI/update/downgradeBasic",
                query: {
                    id: this.formData.id,
                    basic_package: this.latterBasicPack
                },
                method: "post",
            };
            await this.$store.dispatch("request/doRequest", obj).then((response) => {
                if (!response) {
                    this.updateBasicPack = false
                    this.formData.basic_package = this.latterBasicPack
                    return
                }
            });
        },
        async addNewBasicPack() {
            var obj = {
                url: "/api/v1/subscriptionAPI/update/upgradeBasic",
                query: {
                    id: this.formData.id,
                    basic_package: this.formData.basic_package
                },
                method: "post",
            };
            await this.$store.dispatch("request/doRequest", obj).then((response) => {
                if (!response) {
                    this.updateBasicPack = false
                    this.formData.basic_package = this.latterBasicPack
                    return
                }

            });
        },
        doUpdateBasicPack() {
            this.removeBasicPack().then((_) => {
                this.addNewBasicPack().then((_) => {
                    this.updateBasicPack = false
                    this.latterBasicPack = this.formData.basic_package
                    this.saveSubscriber()
                })
            })
        },
        warningUpadateBasicPack() {
            if (!this.formData.id) {
                return
            }
            if (this.formData.basic_package == this.latterBasicPack) {
                this.updateBasicPack = false
                return
            }
            let obj = {
                message: `Você tem certeza que deseja alterar o pacote básico?<br/> <b>A alteração só ocorrerá quando a subscrição for salva.</b>`,
                title: "Alteração do pacote básico",
                callback: (_) => {
                    this.updateBasicPack = true
                },
                cancelCallback: (_) => {
                    this.formData.basic_package = this.latterBasicPack
                    this.updateBasicPack = false
                },
                type: "warning",
                btConfirm: { name: "Sim", show: true },
                btCancel: { name: "Não", show: true },
            };
            this.$store.commit("confirmMessage/showConfirmMessage", obj);
        },
        getBasicPacks(id) {
            // console.log('contrat sel', this.formData.contract_id)
            if (!id) {
                var contractSel = this.contractsList.filter((obj) => {
                    return obj.id == this.formData.contract_id;
                });
            }
            let idService = id ? id : contractSel[0].service.id;
            this.basicPacks = [];
            // console.log('ssssadsda', this.formData.contract_id)

            // return;
            var obj = {
                url: "/api/urn/get-all",
                query: { id_service: idService },
                method: "post",
            };
            //console.log(obj);
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                //console.log(response);
                this.basicPacks = [
                    ...response.data.filter((obj) => {
                        if (obj.status == "ativa" && obj.urn_basic) {
                            return obj
                        }

                    }),
                ];
                // console.log('basicos', this.basicPacks)
                // this.updatePacs();
            });
        },
        setEscClose() {
            let _this = this;
            document.onkeydown = function (evt) {
                evt = evt || window.event;
                var isEscape = false;
                if ("key" in evt) {
                    isEscape = (evt.key === "Escape" || evt.key === "Esc");
                } else {
                    isEscape = (evt.keyCode === 27);
                }
                if (isEscape) {
                    _this.toogleSubscriberDilog()
                    document.onkeydown = null;
                }
            };
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        confirmRemovePac(data) {
            //console.log('confirm',this.formData)
            let obj = {
                message: `O pacote <b>"${data.name}"</b> será removido da subscrição de <b>"${this.formData.name}"</b>, Deseja continuar?`,
                title: "Remover pacote",
                callback: (_) => {
                    this.callbackRemoveUpdatePacs(data);
                },
                type: "warning",
                btConfirm: { name: "Sim", show: true },
                btCancel: { name: "Não", show: true },
            };
            this.$store.commit("confirmMessage/showConfirmMessage", obj);
        },
        callbackRemoveUpdatePacs(item) {
            //console.log("extpacout", this.lastExtPacs, item);
            let subscription_content = this.lastExtPacs.filter(
                (pac) => pac.content_id == item.id
            );
            var obj = {
                url: "/api/v1/subscriptionAPI/update/downgrade",
                query: {
                    id: this.formData.id,
                    subscription_content_id: subscription_content[0].id,
                },
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.updatePacs(item);
                this.buildEditData(response.data);
            });
        },
        confirmAddNewPac(data) {
            let obj = {
                message: `O pacote <b>"${data.name}"</b> será adicionado a subscrição <b>"${this.formData.name}"</b>, Deseja continuar?`,
                title: "Adicionar pacote",
                callback: (_) => {
                    this.callbackUpdatePacs(data);
                },
                type: "success",
                btConfirm: { name: "Sim", show: true },
                btCancel: { name: "Não", show: true },
            };
            this.$store.commit("confirmMessage/showConfirmMessage", obj);
        },
        callbackUpdatePacs(item) {
            var obj = {
                url: "/api/v1/subscriptionAPI/update/upgrade",
                query: {
                    id: this.formData.id,
                    urn_ids: [item.id],
                },
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                if (!response) {
                    return;
                }
                this.updatePacs(item);
                this.buildEditData(response.data);
                setTimeout(() => {
                    this.urn_id = "";
                    document
                        .getElementById("comboPacs")
                        .getElementsByTagName("button")[0]
                        .click();
                }, 10);
            });
        },
        saveSubscriber() {
            let tabs = document.querySelectorAll("#subscriberFormTab .v-tab");
            let userSbscrib = this.$refs.userSubscriber.validate();
            let dataSubscrib = this.$refs.dataSubscriber.validate();
            if (!userSbscrib && !dataSubscrib) {
                return;
            } else if (!userSbscrib) {
                tabs[0].click();
                return;
            } else if (!dataSubscrib) {
                tabs[1].click();
                return;
            }
            let query = { ...this.formData };
            query.partner_id = localStorage.getItem("partner_id");
            console.log('aqui')
            var contractSel = this.contractsList.filter((obj) => {
                return obj.id == this.formData.contract_id.id;
            });
            query.service_id = contractSel[0].service.id;
            delete query.urn_id;
            delete query.extraPacs;
            // if (this.updateBasicPack) {
            //   this.doUpdateBasicPack()
            // }
            query.frontend = true;
            this.latterBasicPack = this.formData.basic_package
            let url = this.formData.id
                ? "/api/v1/subscriptionAPI/update/subscriber"
                : "/api/v1/subscriptionAPI/create";
            var obj = {
                url: url,
                query: query,
                method: "post",
            };
            if (!this.formData.id) {
                query.contract_id = query.contract_id.id
            }
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                if (!response) {
                    return;
                }
                this.$store.commit("showAlert", {
                    message: `Subscrição ${this.formData.id ? "atualizada" : "criada"
                        } com sucesso`,
                    type: "success",
                });
                this.getSubscribers();
                this.buildEditData(response.data).then((_) => {
                    //console.log("toedit");
                    this.formData = { ...this.objEditSubscription };
                    setTimeout(() => {
                        this.formData.basic_package = this.latterBasicPack
                    }, 10);
                    this.getExtraPacs(this.objEditSubscription);
                });
            });
        },
    },
    watch: {
        subscriberDilog(v) {
            if (v) {
                this.setEscClose()
                console.log('sssss', this.$store.state.clientPermissions.subscriberJustView)
                setTimeout(() => {
                    let tabs = document.querySelectorAll("#subscriberFormTab .v-tab");
                    tabs[1].click();
                    setTimeout(() => {
                        tabs[0].click();
                    }, 10);
                }, 10);
                // console.log("aaa");
                if (this.objEditSubscription) {
                    this.latterBasicPack = this.objEditSubscription.basic_package
                    this.formData = { ...this.objEditSubscription };
                    this.getExtraPacs(this.objEditSubscription);
                    this.getBasicPacks()
                } else {
                    this.getBasicPacks(this.contractsList[0].service.id)
                }
                this.formData.contract_id = this.contractsList[0]
                // console.log('teste')
            } else {
                document.onkeydown = null;
                this.updateBasicPack = false
                this.$store.commit(
                    "clientPermissions/subscriberJustView",
                    false
                );
                this.formData = { ...resetSubscriberForm };
                this.$refs.userSubscriber.resetValidation();
                this.$refs.dataSubscriber.resetValidation();
            }
        },
    },
    props: {
        updatePacs: Function,
        getExtraPacs: Function,
        subscriberDilog: Boolean,
        toogleSubscriberDilog: Function,
        getSubscribers: Function,
        contractsList: Array,
        unContracteds: Array,
        contracteds: Array,
        objEditSubscription: Object,
        buildEditData: Function,
        lastExtPacs: Array,
        lastHistory: Array,
        editSbscription: Function,
    },
    data() {
        return {
            latterBasicPack: "",
            updateBasicPack: false,
            basicPacks: [],
            show1: false,
            show3: false,
            formData: { ...resetSubscriberForm },
            validTab1: true,
            validTab2: true,
            tab: null,
        };
    },
    components: {
        ExtraPacsList,
        HistoryList,
        HistoryLogins
    },
};
</script>

<style></style>