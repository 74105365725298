<template>
  <v-data-table :headers="headers" :items="contracteds" :items-per-page="10" class="elevation-1 mb-4"
    no-data-text="Ainda não existem pacotes adicionais nesta assinatura.">
    <template v-slot:item.price="{ item }">
      R$ {{ item.price | formatPrice }}
    </template>
    <template v-slot:item.actions="{ item }"><v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :disabled="$store.state.clientPermissions.subscriberJustView" v-bind="attrs" v-on="on"
            class="btMicro px-1" @click="confirmRemovePac(item)" x-small color="red" dark><v-icon center x-small>
              mdi-delete-forever </v-icon></v-btn>
        </template>
        <span class="miniTtip">Excluir pacote</span>
      </v-tooltip></template>
  </v-data-table>
</template>

<script>
import MyFilters from "../../../filters/genericFilters";
export default {
  methods: {},
  props: {
    contracteds: Array,
    updatePacs: Function,
    confirmRemovePac: Function,
  },
  data() {
    return {
      headers: [
        { text: "Pacote", value: "name", align: "start", sortable: false },
        {
          text: "Valor",
          align: "start",
          sortable: false,
          value: "price",
        },
        {
          text: "Ações",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
};
</script>

<style></style>