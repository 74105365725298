<template>
    <v-row justify="center">
        <v-dialog v-model="checkpointHomeDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primaryNew">
                    <v-toolbar-title>CHECKPOINT</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon dark @click="toogleCheckpointHomeDialog">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </template>
                            <span>Fechar</span>
                        </v-tooltip>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <CheckpointList :checkpointHomeDialog="checkpointHomeDialog" />
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import CheckpointList from "./ListCheckpoint"
export default {
    props: {
        checkpointHomeDialog: Boolean,
        toogleCheckpointHomeDialog: Function
    },
    components: {
        CheckpointList,
    },
}
</script>

<style lang="scss" scoped></style>