<template>
    <v-dialog scrollable light v-model="logDialog" persistent max-width="800">
        <v-card>
            <v-card-title style="border-bottom: 1px solid #ccc;" class="headline px-2">
                <v-app-bar flat color="rgba(0, 0, 0, 0)">
                    <v-toolbar-title class="text-h5 pl-0">
                        Detalhes de log
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- <v-fab-transition> -->
                    <v-btn @click="toogleLogDialog" class="mt-7" color="red" fab icon dark x-small absolute top right>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <!-- </v-fab-transition> -->
                </v-app-bar>
            </v-card-title>
            <v-card-text>
                <v-card flat>
                    <v-card-text class="py-0">
                        <v-row no-gutters class="px-1">
                            <v-col cols="12" md="12" class="mt-3">
                                <h4 class="mb-2 error--text">Mensagem principal</h4>
                                <v-container class="px-0 pb-0" fluid>
                                    <v-textarea name="input-7-1" filled label="" auto-grow :value="stringMessage"
                                        readonly></v-textarea>
                                </v-container>
                            </v-col>
                            <v-col cols="12" md="12" class="mt-0">
                                <h4 class="mb-2 error--text">Lista de mensagens</h4>
                                <v-container class="px-0" fluid>
                                    <pre style="overflow: auto"
                                        class="black green--text text--accent-3"><code>{{ jsonMessage }}</code></pre>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="toogleLogDialog">
                    Fechar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            // headers: [
            //     {
            //         text: "Empresa",
            //         value: "requesting_company",
            //         align: "start",
            //         sortable: false,
            //     },
            //     {
            //         text: "E-mail",
            //         value: "requesting_email",
            //         align: "start",
            //         sortable: false,
            //     },
            //     {
            //         text: "Tipo de operação",
            //         value: "operation_type",
            //         align: "start",
            //         sortable: false,
            //     },

            //     {
            //         text: "Quantidade de licenças",
            //         value: "qtd",
            //         align: "center",
            //         sortable: false,
            //     },
            // ]
        }
    },
    props: {
        jsonMessage: String,
        stringMessage: String,
        logs: Array,
        logDialog: Boolean,
        toogleLogDialog: Function
    },
}
</script>

<style lang="scss" scoped></style>