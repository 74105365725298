<template>
    <v-card style="border: 1px solid #ccc;" elevation="0" :class="[!hasProduct ? 'disabbleCard' : '']" max-width="300">
        <v-img style="border-bottom: 1px solid #ccc;" cover height="180"
            :src="require(`@/assets/newArea/${image}`)"></v-img>
        <v-card-text class="text-left pa-0">
            <v-card-title>{{ title }}</v-card-title>
            <v-card-subtitle class="pb-0">
                <span class="mr-1"><small>Destaque</small></span>
                <v-icon class="mb-1" color="orange" size="small">whatshot</v-icon>
            </v-card-subtitle>
        </v-card-text>
        <v-card-text class="text-left">
            <div>{{ infoText ? infoText : 'Small plates, salads & sandwiches - an intimate setting with 12 indoorseats.'
                }}</div>
        </v-card-text>
        <v-divider class="mx-4 mb-1"></v-divider>
        <v-card-actions class="d-flex justify-center">
            <v-btn :disabled="!hasProduct" :to="toPage ? toPage : null" width="250" class="text-none mb-4 white--text" :color="btColor"
                size="small" @click="openArea">
                {{ btText }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: {
        hasProduct: Boolean,
        openArea: Function,
        title: String,
        infoText: String,
        image: String,
        btColor: String,
        btText: String,
        toPage: String
    },
}
</script>

<style lang="scss" scoped>
.disabbleCard{
    opacity: .9;
}

.disabbleCard .v-image{
    opacity: .3;
}
</style>