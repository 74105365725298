<template>
    <v-data-table :footer-props="{
        'items-per-page-text': 'Provedores por página',
    }" :loading="false" :headers="headers" :items="licenses" :items-per-page="5" class="elevation-1 mt-6 mb-4">
        <template v-slot:no-data>
            <div class="text-xs-center" style="min-height: 90px; padding-top: 35px">
                Não há dados
            </div>
        </template>
        <template v-slot:item.requesting_company="{ item }">
            <div>{{ item.requesting_company }}</div>
            <div><small>{{ item.tenant }}</small></div>
        </template>
        <template v-slot:item.document="{ item }">
            <div>{{ item.document | formatCnpjCpf }}</div>
            <div><small>{{ item.requesting_email }}</small></div>
        </template>
        <template v-slot:item.actions="{ item }">
            <!-- <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="btMicro px-1 mr-3"
                        @click="editLicense(item, licenses.indexOf(item))" x-small color="primary" dark><v-icon center
                            x-small> edit </v-icon></v-btn>
                </template>
<span class="miniTtip">Editar licença</span>
</v-tooltip> -->
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="btMicro px-1"
                        @click="confirmDelLicense(item, licenses.indexOf(item))" x-small color="red" dark><v-icon center
                            x-small> mdi-delete-forever </v-icon></v-btn>
                </template>
                <span class="miniTtip">Excluir licença</span>
            </v-tooltip>
        </template>
    </v-data-table>
</template>

<script>
import MyFilters from "../../../../filters/genericFilters";
export default {
    props: {
        editLicense: Function,
        licenses: Array,
        confirmDelLicense: Function,
    },
    data() {
        return {
            headers: [
                //         "order":"11500000001",
                //   "qtd":"20",
                //   "operation_type":"contratação",
                //   "licence_id":"1",
                //   "requesting_company":"Empresa 1",
                //   "document":"69862338000159",
                //   "requesting_email":"jgrs2174@gmail.com"
                {
                    text: "Empresa / Tenant",
                    align: "start",
                    sortable: true,
                    value: "requesting_company",
                },
                { text: "Documento / E-mail", value: "document", sortable: false },
                { text: "Operação", value: "operation_type", sortable: false },
                { text: "Quantidade", value: "qtd", align: "center", sortable: false },
                { text: "Ordem", value: "order", align: "center", sortable: false },
                { text: "Ações", value: "actions", align: "center", sortable: false },
            ],
        };
    },
};
</script>

<style></style>