<template>
    <v-container class="fill-height px-12">
        <v-responsive class="align-start text-center fill-height pa-1">
            <!-- <v-card min-height="200" elevation="3" width="100%" class="mx-auto"> -->
            <v-row class="mt-1 mb-2 d-flex align-start justify-space-between">
                <v-col cols="12">
                    <h4 class="titlePages text-left font-weight-medium mb-3">Gerencie os seus produtos
                    </h4>
                    <p style="max-width: 50%;" class="text-body-2 font-weight-light text-left mt-2">
                        Transforme a forma como você gerencia seus produtos.<br/>
                        Tudo o que você precisa para gerenciar, administrar suas subscrições e cobrnças em um só lugar.
                    <!-- <br/> -->
                    </p>
                </v-col>
            </v-row>
            <div class="d-flex align-start justify-space-between">
                <CardClient :openArea="toogleDgoDialog"
                    :hasProduct="$store.state.clientPermissions.newAreapermissions.dtvgo.hasProd"
                    infoText="Gerencie todas suas subscrições e assinaturas DGO, a TV por assinatura mais completa do Brasil."
                    title="DIRECTV GO" image="dgo-banner.jpg" btColor="#f6821d" btText="ÁREA ADM. DGO" />
                <CardClient :openArea="toogleCheckpointHomeDialog"
                    infoText="Gerencie suas licença checkpoint, verifique os status, quantidade, detalhes e muito mais."
                    :hasProduct="$store.state.clientPermissions.newAreapermissions.checkpoint.hasProd"
                    title="CHECKPOINT" image="checkpoint-banner.jpg" btColor="#ff177a" btText="ÁREA ADM. CHECKPOINT" />
                <CardClient :hasProduct="$store.state.clientPermissions.newAreapermissions.pagamento.hasProd"
                infoText="Tudo o que você precisa para receber dos seus clientes com transparência, agilidade e facilidade"    
                toPage="/cliente/pagamentos" :openArea="_ => { }" title="PAGAMENTOS" image="boletos-banner.jpg"
                    btColor="info" btText="ÁREA ADM. PAGAMENTOS" />
            </div>
        </v-responsive>
        <DgoDialog :toogleDgoDialog="toogleDgoDialog" :dgoDialog="dgoDialog" />
        <CheckpointHomeDialog :toogleCheckpointHomeDialog="toogleCheckpointHomeDialog"
            :checkpointHomeDialog="checkpointHomeDialog" />
    </v-container>
</template>

<script>
import DgoDialog from "./dtvGo/DgoHome"
import CheckpointHomeDialog from "./checkpoint/CheckpointHome.vue"
import CardClient from "./HomeCard.vue"
export default {
    methods: {
        toogleDgoDialog() {
            this.dgoDialog = !this.dgoDialog
        },
        toogleCheckpointHomeDialog() {
            this.checkpointHomeDialog = !this.checkpointHomeDialog
        }
    },
    data: () => ({
        hasDgo: false,
        dgoDialog: false,
        checkpointHomeDialog: false,
    }),
    components: {
        CardClient,
        DgoDialog,
        CheckpointHomeDialog
    },

}
</script>

<style lang="scss" scoped></style>