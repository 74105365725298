<template>
    <v-row justify="center">
        <v-dialog v-model="dgoDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primaryNew">
                    <v-toolbar-title>SUBSCRIÇÕES DIRECTV GO</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon dark @click="toogleDgoDialog">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </template>
                            <span>Fechar</span>
                        </v-tooltip>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <DGoList/>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import DGoList from "./ListDgo"
export default {
    props: {
        dgoDialog: Boolean,
        toogleDgoDialog: Function
    },
    components: {
        DGoList,
    },
}
</script>

<style lang="scss" scoped></style>