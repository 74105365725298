<template>
    <!-- <v-row no-gutters class="px-1 pt-0"></v-row> -->
    <div class="pb-15" style="max-width: 1600px; margin: auto; position: relative;">
        <!-- v-if="$refs.subscriberList ? $refs.subscriberList.permissionList.add : false" -->
        <v-row no-gutters class="px-1 pt-0">
            <v-col cols="12" md="12" class="pl-2 pr-3 mt-12 mb-8">
                <h2 class="listTitles font-weight-medium">Minhas subscrições</h2>
                <div v-if="subscriberActivies && subscriberDeactivies" style="position: absolute; right: 0;"
                    class="d-flex">
                    <div class="d-flex">
                        <v-avatar class="mr-3 mt-1" color="green lighten-5" rounded size="57"><v-icon large
                                color="green lighten-1">verified</v-icon></v-avatar>
                        <div class="">
                            <div class="font-weight-medium text-subtitle-1">Ativas</div>
                            <div class="text--lighten-1 green--text font-weight-medium text-h4">{{ subscriberActivies }}
                            </div>
                        </div>
                    </div>
                    <div class="d-flex ml-15 mr-7">
                        <v-avatar class="mr-3 mt-1" color="orange lighten-5" rounded size="57"><v-icon large
                                color="orange lighten-1">block</v-icon></v-avatar>
                        <div class="">
                            <div class="font-weight-medium text-subtitle-1">Canceladas</div>
                            <div class="warning--text text--lighten-1 font-weight-medium text-h4">{{
                                subscriberDeactivies }}</div>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="3" class="pl-2 pr-3">
                <v-menu v-model="initialDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                    min-width="290px">
                    <template v-slot:activator="{ on }">
                        <v-text-field prepend-icon="filter_alt" clearable v-model="initialDateFormated"
                            label="Data inicial" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker :max="disableToday" no-title v-model="initialDate"
                        @input="initialDateMenu = false"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="3" class="pl-2 pr-3">
                <v-menu v-model="endDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                    min-width="290px">
                    <template v-slot:activator="{ on }">
                        <v-text-field clearable v-model="endDateFormated" label="Data final" readonly
                            v-on="on"></v-text-field>
                    </template>
                    <v-date-picker no-title v-model="endDate" @input="endDateMenu = false"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="2" class="mt-3">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" @click="getSubscribersByFilters" class="ma-2" x-small fab
                            color="primary">
                            <v-icon small>search</v-icon>
                        </v-btn>
                    </template>
                    <span>Pesquisar</span>
                </v-tooltip>
            </v-col>
            <!-- <v-col cols="12" md="1" class="mt-3"></v-col> -->
        </v-row>
        <v-col cols="12" md="3" class="py-0 pl-4 pr-3 mt-0 text--right">
            <v-switch color="primaryNew" class="mt-2" v-model="canceled" label="Somente canceladas"></v-switch>
        </v-col>
        <v-col cols="12" md="12" class="pt-0 pl-2 pr-3 mt-0 text-right">
            <v-btn v-if="dgoPermission.add" class="ma-2 py-2" color="success" @click="toogleSubscriberDilog">
                <span class="noIconPlus">+</span> Adicionar subscrição
            </v-btn>
        </v-col>
        <v-data-table :class="[subscribers ? 'hideCol' : '', 'elevation-1 thBorderBold']" id="subscriberTable"
            :headers="headers" :items="subscribers" :items-per-page="5" :item-class="rowClasses" no-data-text="Você ainda não possui subscrições. Clique no botão 'Adicionar
                subscrição' localizado no canto direito superior da tela e crie uma
                nova subscrição." no-results-text="O filtro atual não retornou nenhum resultado">
            <template v-slot:item.status="{ item }">
                <v-tooltip top max-width="230" :color="item.status == 'tentativas atingidas' ? 'red darken-4' : ''">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="cursorHand" v-bind="attrs" v-on="on"
                            :color="subscriberStatusColor(item.status)">{{
                                subscriberStatus(item.status) }}</v-icon>
                    </template>
                    <span class="text-center" v-html="subscriberTooltip(item.status, item.date_agended_cancel)"></span>
                </v-tooltip>
            </template>
            <template v-slot:item.subscriber.name="{ item }">
                <div>
                    {{ item.subscriber.name }}
                </div>
                <div class="caption">{{ item.subscriber.email }}</div>
            </template>
            <template v-slot:item.subscription_content_sum_value="{ item }">
                R$ {{ item.subscription_content_sum_value | formatPrice }}
            </template>
            <template v-slot:item.subscription_date="{ item }">
                {{ item.subscription_date | formatDate }}
            </template>
            <template v-slot:item.subscriber.document="{ item }">
                <span v-if="item.subscriber.document">{{ item.subscriber.document | formatCnpjCpf }}</span>
                <span v-else> - </span>
            </template>

            <template v-slot:item.actions="{ item }">
                <div v-if="item.status == 'cancelado'"><v-btn color="primaryNew" @click="editSbscription(item.id, true)"
                        outlined rounded x-small>
                        + Detalhes
                    </v-btn>
                </div>
                <v-menu v-if="item.status != 'cancelado'" transition="slide-y-transition" left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon color="primaryNew">
                            <v-icon>more_vert</v-icon>
                        </v-btn>
                    </template>
                    <v-list dense rounded class="">
                        <v-subheader class="text-subtitle-2"><b class="primaryNew--text">{{ item.subscriber.name
                                }}</b></v-subheader>
                        <v-divider class="mb-2"></v-divider>
                        <!-- <v-list-item-group color="primaryNew" class=""> -->
                            <v-list-item v-if="dgoPermission.edit" @click="editSbscription(item.id)">
                                <v-list-item-icon class="mr-4">
                                    <v-icon>drive_file_rename_outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Editar subscrição</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="dgoPermission.bock"
                                @click="
                                    confirmBlockUmblockSubscription(item, subscribers.indexOf(item), (item.status != 'ativo' ? 'Desbloquear' : 'Bloquear'))">
                                <v-list-item-icon class="mr-4">
                                    <v-icon>{{ item.status != 'ativo' ? 'check_circle' :
                                        'block' }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.status != 'ativo' ? 'Desbloquear' : 'Bloquear' }}
                                        subscrição</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="dgoPermission.cancel" @click="confirmCancelSubscription(item, subscribers.indexOf(item))
                                ">
                                <v-list-item-icon class="mr-4">
                                    <v-icon>{{ item.status != "agendado" ? 'delete_forever' : 'published_with_changes'
                                        }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{
                                        item.status != "agendado" ? 'Cancelar subscrição' : 'Reativar subscrição'
                                    }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="dgoPermission.changePassword" @click="toogleChangePwdDialog(item)">
                                <v-list-item-icon class="mr-4">
                                    <v-icon>vpn_key</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Alterar senha</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        <!-- </v-list-item-group> -->
                    </v-list>
                    <!-- <v-list>
                        <v-list-item>
                            <v-list-item-title @click="toogleChangePwdDialog(item)">Alterar senha</v-list-item-title>
                        </v-list-item>
                    </v-list> -->
                </v-menu>
            </template>


            <!-- <template v-slot:item.actions="{ item }">
                <div v-if="item.status == 'cancelado'"><v-btn color="primary" @click="editSbscription(item.id, true)"
                        outlined rounded x-small>
                        + Detalhes
                    </v-btn></div>
                <div v-if="item.status != 'cancelado'">
                    <v-tooltip
                        v-if="permissionList.edit && item.status != 'agendado' && item.status != 'tentativas atingidas'"
                        top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="btMicro px-1 mr-1" @click="editSbscription(item.id)"
                                x-small color="primary" dark><v-icon center x-small> edit </v-icon></v-btn>
                        </template>
                        <span class="miniTtip">Editar subscrição</span>
                    </v-tooltip>
                    <v-tooltip
                        v-if="permissionList.bock && item.status != 'agendado' && item.status != 'tentativas atingidas'"
                        top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-show="item.status != 'ativo'" v-bind="attrs" v-on="on" class="btMicro px-1 mr-1"
                                @click="
            confirmBlockUmblockSubscription(
                item,
                subscribers.indexOf(item),
                'Desbloquear'
            )
            " x-small color="success" dark><v-icon center x-small> check_circle </v-icon></v-btn>
                        </template>
                        <span class="miniTtip">Desbloquear subscrição</span>
                    </v-tooltip>
                    <v-tooltip
                        v-if="permissionList.bock && item.status != 'agendado' && item.status != 'tentativas atingidas'"
                        top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-show="item.status == 'ativo'" v-bind="attrs" v-on="on" class="btMicro px-1 mr-1"
                                @click="
            confirmBlockUmblockSubscription(
                item,
                subscribers.indexOf(item),
                'Bloquear'
            )
            " x-small color="grey" dark><v-icon center x-small> block </v-icon></v-btn>
                        </template>
                        <span class="miniTtip">Bloquear subscrição</span>
                    </v-tooltip>
                    <v-tooltip top v-if="permissionList.cancel">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="btMicro px-1 mr-1" @click="
            confirmCancelSubscription(item, subscribers.indexOf(item))
            " x-small :color="item.status != 'agendado' ? 'red' : 'success'" dark><v-icon center x-small>
                                    {{
            item.status != "agendado"
                ? "mdi-delete-forever"
                : "published_with_changes"
        }}</v-icon></v-btn>
                        </template>
                        <span class="miniTtip">{{
                item.status != "agendado"
                    ? `${item.status == "tentativas atingidas"
                        ? "Nova solicitaçã de cancelamento"
                        : "Cancelar subscrição"
                    }`
                    : "Reativar subscrição"
            }}</span>
                    </v-tooltip>
                    <v-tooltip top v-if="permissionList.changePassword && item.status == 'ativo'">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="btMicro px-1" @click="toogleChangePwdDialog(item)"
                                x-small color="teal lighten-1" dark><v-icon center x-small>vpn_key</v-icon></v-btn>
                        </template>
                        <span class="miniTtip">Alterar senha</span>
                    </v-tooltip>
                </div>
            </template> -->
        </v-data-table>
        <ChangePwd :subscriberId="subscriberId" :resetPwdDialog="resetPwdDialog"
            :toogleChangePwdDialog="toogleChangePwdDialog" />
        <SubscriberDialog ref="subscriberDialog" :contractsList="contractsList" :subscriberDilog="subscriberDilog"
            :toogleSubscriberDilog="toogleSubscriberDilog" :getSubscribers="getSubscribers" :getExtraPacs="getExtraPacs"
            :unContracteds="unContracteds" :updatePacs="updatePacs" :contracteds="contracteds"
            :objEditSubscription="objEditSubscription" :buildEditData="buildEditData" :lastExtPacs="lastExtPacs"
            :lastHistory="lastHistory" :editSbscription="editSbscription" />
    </div>
</template>

<script>
import * as moment from "moment";
import MyFilters from "../../../../filters/genericFilters";
import ChangePwd from "../../../../components/common/ChangePwd.vue";
import SubscriberDialog from "../../../../components/clients/subscriber/SubscriberDialogNew.vue";
export default {
    // watch: {
    //     subscriberDilog(v) {
    //         if (v) {
    //             this.setEscClose()
    //             // console.log('sssss', this.$store.state.clientPermissions.subscriberJustView)
    //             setTimeout(() => {
    //                 let tabs = document.querySelectorAll("#subscriberFormTab .v-tab");
    //                 tabs[1].click();
    //                 setTimeout(() => {
    //                     tabs[0].click();
    //                 }, 10);
    //             }, 10);
    //             // console.log("aaa");
    //             if (this.objEditSubscription) {
    //                 this.latterBasicPack = this.objEditSubscription.basic_package
    //                 this.formData = { ...this.objEditSubscription };
    //                 this.getExtraPacs(this.objEditSubscription);
    //                 this.getBasicPacks()
    //             }
    //         } else {
    //             document.onkeydown = null;
    //             this.updateBasicPack = false
    //             this.$store.commit(
    //                 "clientPermissions/subscriberJustView",
    //                 false
    //             );
    //             this.formData = { ...resetSubscriberForm };
    //             this.$refs.userSubscriber.resetValidation();
    //             this.$refs.dataSubscriber.resetValidation();
    //         }
    //     },
    // },
    methods: {
        setEscClose() {
            let _this = this;
            document.onkeydown = function (evt) {
                evt = evt || window.event;
                var isEscape = false;
                if ("key" in evt) {
                    isEscape = (evt.key === "Escape" || evt.key === "Esc");
                } else {
                    isEscape = (evt.keyCode === 27);
                }
                if (isEscape) {
                    _this.toogleSubscriberDilog()
                    document.onkeydown = null;
                }
            };
        },
        editSbscription(id, justView) {

            var obj = {
                url: "/api/v1/subscriptionAPI/getById",
                query: { id: id },
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                // console.log(response.data);
                if (justView) {
                    this.$store.commit(
                        "clientPermissions/subscriberJustView",
                        true
                    );
                }
                this.buildEditData(response.data);
                //this.direcTvGoAdds = [...response.data];
            });
        },
        async buildEditData(data) {
            this.lastExtPacs = [...data.subscription_content];
            this.lastHistory = [...data.subscription_history];
            this.objEditSubscription = {
                id: data.id,
                extraPacs: [],
                password: "",
                confirmPwd: "",
                urn_ids: [],
                partner_subscriber_id: data.partner_subscriber_id,
                email: data.subscriber.email,
                name: data.subscriber.name,
                document: data.subscriber.document,
                phone: data.subscriber.phone,
                address: data.subscriber.address,
                number: data.subscriber.number,
                complement: data.subscriber.complement,
                neighborhood: data.subscriber.neighborhood,
                city: data.subscriber.city,
                state: data.subscriber.state,
                zip_code: data.subscriber.zip_code,
                contract_id: data.contract_id,
            };
            // console.log('aqui')
            data.subscription_content.map(sub => {

                if (sub.urn_basic) {
                    this.objEditSubscription.basic_package = sub.name_content
                }
            })
            this.subscriberDilog = true;
        },
        updatePacs(pac) {
            if (pac) {
                if (pac.contracted) {
                    this.contracteds = this.contracteds.filter(
                        (item) => item.id != pac.id
                    );
                    pac.contracted = false;
                    this.unContracteds.push(pac);
                } else {
                    this.unContracteds = this.unContracteds.filter(
                        (item) => item.id != pac.id
                    );
                    pac.contracted = true;
                    this.contracteds.push(pac);
                }
                //console.log(pac.contracted);
            } else {
                this.contracteds = this.direcTvGoAdds.filter(
                    (contracted) =>
                        contracted.contracted && contracted.id != 3 && contracted.id != 7
                );
                this.unContracteds = this.direcTvGoAdds.filter(
                    (unContracted) =>
                        !unContracted.contracted &&
                        unContracted.id != 3 &&
                        unContracted.id != 7
                );
            }
        },
        getExtraPacs(data, callback) {
            var contractSel = this.contractsList.filter((obj) => {
                return obj.id == data.contract_id;
            });
            let idService = contractSel[0].service.id;
            this.direcTvGoAdds = [];

            //return;
            var obj = {
                noLoader: true,
                url: "/api/v1/subscriptionAPI/contents",
                query: {
                    service_id: idService,
                    subscription_id: data.id,
                    //contract_id: this.$refs.subscriberDialog.formData.contract_id,
                },
                method: "post",
            };
            //console.log(obj);
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                //console.log(response);
                this.direcTvGoAdds = [
                    ...response.data.filter((obj) => {
                        if (obj.status == "ativa" && !obj.urn_basic) {
                            return obj
                        }

                    }),
                ];
                this.updatePacs();
            });
        },
        toogleSubscriberDilog() {

            // console.log(this.tab)
            if (this.tab) {
                this.$refs.chkPoint.toogleCheckpointDialog()
                return
            }

            this.subscriberDilog = !this.subscriberDilog;
            this.unContracteds = [];
            this.contracteds = [];
            this.lastExtPacs = [];
            this.lastHistory = [];
            this.objEditSubscription = null;
        },
        confirmReactiveSubscription(item, index) {
            //console.log(item);
            let obj = {
                message: `Você deseja reativar a subscrição <b>${item.service.name}</b> de <b>${item.subscriber.name}</b>?`,
                title: `Reativar subscrição`,
                callback: (_) => {
                    this.reactiveSubscription(item.id, index);
                },
                type: "warning",
                btConfirm: { name: "Sim", show: true },
                btCancel: { name: "Não", show: true },
            };
            this.$store.commit("confirmMessage/showConfirmMessage", obj);
        },
        reactiveSubscription(id, index) {
            var obj = {
                url: "/api/v1/subscriptionAPI/deleteScheduledCancellation",
                query: { id: id },
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {

                let index = this.subscribers.findIndex((subscribe) => subscribe.id === id);
                this.subscribers.splice(index, 1);

                this.getSubscribers("nLoad", null);

                this.$store.commit("showAlert", {
                    message: "Subscrição reativada com sucesso",
                    type: "success",
                });
            });
        },
        scheduledCancellation(item, index) {
            let obj = {
                message: `Você pode realizar o cancelameto imediatamente, ou, agendá-lo para o final do período vigente. <br/> <b>Como deseja realizar o cancelamento?</b>`,
                title: `Agendar cancelamento`,
                callback: (_) => {
                    this.cancelubscription(item.id, index, false, true);
                },
                cancelCallback: (_) => {
                    this.cancelubscription(item.id, index, false, false);
                    console.log('cancelCallback agenda')
                },
                type: "warning",
                btConfirm: { name: "Agendar", show: true },
                btCancel: { name: "Imediatamente", show: true },
            };
            this.$store.commit("confirmMessage/showConfirmMessage", obj);
        },
        confirmCancelSubscription(item, index) {
            //console.log(item);
            if (item.status == "agendado") {
                this.confirmReactiveSubscription(item, index);
                return;
            }
            let cancelAgain = false;
            if (item.status == "tentativas atingidas") {
                cancelAgain = true;
            }
            let obj = {
                message: `Você deseja cancelar a subscrição <b>${item.service.name}</b> de <b>${item.subscriber.name}</b>?`,
                title: `Cancelar subscrição`,
                callback: (_) => {
                    if (item.scheduled_cancellation &&
                        item.status != "agendado" &&
                        item.status != "tentativas atingidas") {
                        setTimeout(() => {
                            this.scheduledCancellation(item, index)
                        }, 100);
                        return
                    } else {
                        this.cancelubscription(item.id, index, cancelAgain);
                    }
                },
                type: "warning",
                btConfirm: { name: "Sim", show: true },
                btCancel: { name: "Não", show: true },
            };
            this.$store.commit("confirmMessage/showConfirmMessage", obj);
        },
        cancelubscription(id, index, cancelAgain, scheduledCancellation) {
            var obj = {
                url: cancelAgain
                    ? "/api/v1/subscriptionAPI/resendCancellationScheduled"
                    : "/api/v1/subscriptionAPI/cancel",
                query: { id: id },
                method: "post",
            };
            if (scheduledCancellation != null) {
                obj.query.scheduled_cancellation = scheduledCancellation
            }
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                // this.subscribers.splice(index, 1);
                if (!response) {
                    return
                }

                this.getSubscribers("nLoad", null);

                this.$store.commit("showAlert", {
                    message: "Subscrição cancelada com sucesso",
                    type: "success",
                });
            });
        },
        confirmBlockUmblockSubscription(item, index, action) {
            //console.log(item);
            let obj = {
                message: `Você deseja ${action.toLowerCase()} a subscrição <b>${item.service.name
                    }</b> de <b>${item.subscriber.name}</b>?`,
                title: `${action}`,
                callback: (_) => {
                    this.blockUmblockSubscription(item.id, index, action);
                },
                type: "warning",
                btConfirm: { name: "Sim", show: true },
                btCancel: { name: "Não", show: true },
            };
            this.$store.commit("confirmMessage/showConfirmMessage", obj);
        },
        blockUmblockSubscription(id, index, action) {
            let url =
                action == "Bloquear"
                    ? "/api/v1/subscriptionAPI/update/block"
                    : "/api/v1/subscriptionAPI/update/unblock";

            var obj = {
                url: url,
                query: { id: id },
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                //console.log(response.data)
                this.subscribers[index].status = response.data.status;
                this.$store.commit("showAlert", {
                    message: "Subscrição atualizada com sucesso",
                    type: "success",
                });
            });
        },
        toogleChangePwdDialog(data) {
            if (data && data.id) {
                this.subscriberId = data.id;
            } else {
                this.subscriberId = null;
            }
            this.resetPwdDialog = !this.resetPwdDialog;
        },
        getSubscribersLenght() {
            let actives = {
                endDate: "", initialDate: "", status: false
            }
            let deactives = {
                endDate: "", initialDate: "", status: true
            }
            this.getSubscribers(true, actives, true).then(respActive => {
                // console.log('filters')
                this.subscriberActivies = respActive
                this.getSubscribers(true, deactives, 'cancel').then(respDeactive => {
                    // console.log('filters22')
                    this.subscriberDeactivies = respDeactive
                })
            })
        },
        async getSubscribers(nLoad, data, totals) {
            let size = null;
            if (data) {
                this.filters = data
            } else {
                this.filters = {
                    endDate: "", initialDate: "", status: false
                }
            }
            var obj = {
                noLoader: nLoad == "nLoad" ? true : false,
                url: "/api/v1/subscriptionAPI/get-all",
                // query: { partner_id: localStorage.getItem("partner_id") },
                query: this.filters,
                method: "post",
            };
            await this.$store.dispatch("request/doRequest", obj).then((response) => {
                // console.log(response);
                if (totals != 'cancel') {
                    this.subscribers = [...response.data];
                    this.subscribers.map((sub) => {
                        sub.price = sub.subscription_content.length
                            ? sub.subscription_content[0].value
                            : 0.0;
                    });
                }
                size = response.data.length
            });
            return size
        },
        getSubscribersByFilters() {
            let query = {
                status: this.canceled,
                initialDate: this.initialDate,
                endDate: this.endDate
            }
            this.getSubscribers(null, query)
        },
        subscriberTooltip(data, date) {
            let tooltipText;
            switch (data) {
                case "ativo":
                    tooltipText = "Ativa";
                    break;
                case "cancelado":
                    tooltipText = "Cancelada";
                    break;
                case "bloqueado":
                    tooltipText = "Bloqueada";
                    break;
                case "agendado":
                    tooltipText = `Cancelamento agendado<br/>para: ${this.$options.filters.formatDate(date)}`;
                    break;
                case "tentativas atingidas":
                    tooltipText = "Falha em todas as tentativas de cancelamento!";
                    break;

                default:
                    console.log(`Sorry, we are out of.`);
            }
            return tooltipText;
        },
        subscriberStatusColor(data) {
            let colorIcon;
            switch (data) {
                case "ativo":
                    colorIcon = "success";
                    break;
                case "cancelado":
                    colorIcon = "grey";
                    break;
                case "bloqueado":
                    colorIcon = "grey";
                    break;
                case "agendado":
                    colorIcon = "red lighten-2";
                    break;
                case "tentativas atingidas":
                    colorIcon = "red darken-4";
                    break;
                default:
                    console.log(`Sorry, we are out of.`);
            }
            return colorIcon;
        },
        subscriberStatus(data) {
            // console.log('-*-*-',data)
            let status;
            switch (data) {
                case "ativo":
                    status = "check_circle";
                    break;
                case "cancelado":
                    status = "block";
                    break;
                case "bloqueado":
                    status = "block";
                    break;
                case "agendado":
                    status = "event_busy";
                    break;
                case "tentativas atingidas":
                    status = "notification_important";
                    break;

                default:
                    console.log(`Sorry, we are out of.`);
            }
            return status;
        },
        rowClasses(item) {
            if (item.status == "tentativas atingidas") {
                return "red lighten-4";
            }
        },
        getContracts(nLoad) {
            // console.log('contract...')
            var obj = {
                noLoader: nLoad == "nLoad" ? true : false,
                url: "/api/v1/subscriptionAPI/contract/partners/get-all",
                // query: { partner_id: localStorage.getItem("partner_id") },
                query: null,
                method: "get",
            };
            //console.log(obj);
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.contractsList = [
                    ...response.data.filter((contract) => {
                        return contract.contract_type != "Company contract" && contract.service.prefix == "dtvgo";
                    }),
                ];
            });
        },
    },
    data() {
        return {
            lastExtPacs: [],
            lastHistory: [],
            unContracteds: [],
            contracteds: [],
            objEditSubscription: null,
            subscribers: [],
            contractsList: [],
            subscriberDilog: false,
            subscriberId: null,
            resetPwdDialog: false,
            items: [
                { title: 'Click Me' },
                { title: 'Click Me' },
                { title: 'Click Me' },
                { title: 'Click Me 2' },
            ],
            subscriberActivies: "",
            subscriberDeactivies: "",
            filters: {
                endDate: "", initialDate: "", status: false
            },
            canceled: false,
            initialDate: "",
            initialDateMenu: false,
            endDate: "",
            endDateMenu: false,
            subscribers: [],
            headers: [
                { text: "Status", value: "status", align: "center", sortable: false },
                {
                    text: "Assinante",
                    value: "subscriber.name",
                    align: "start",
                    sortable: true,
                },
                {
                    text: "CPF",
                    value: "subscriber.document",
                    align: "left",
                    sortable: false,
                },
                {
                    text: "Valor total",
                    value: "subscription_content_sum_value",
                    align: "start",
                    sortable: false,
                },
                {
                    text: "Data da subscrição",
                    value: "subscription_date",
                    sortable: false,
                    align: "center",
                },
                { text: "Ações", value: "actions", sortable: false },
            ],
        }
    },
    mounted() {
        this.getSubscribersLenght();
        this.getContracts("nLoad");
    },
    computed: {
        dgoPermission() {
            return this.$store.state.clientPermissions.newAreapermissions.dtvgo
        },
        disableToday() {
            return moment().format('YYYY-MM-DD')
        },
        initialDateFormated: {
            get: function () {
                let date = moment(this.initialDate).format("DD/MM/YYYY");
                return date == "Invalid date" ? null : date;
            },
            // setter
            set: function (newValue) {
                if (!newValue) {
                    this.initialDate = "";
                }
                let date = moment(this.initialDate).format("DD/MM/YYYY");
                if (date == "Invalid date") {
                    return null;
                }
            },
        },
        endDateFormated: {
            get: function () {
                let date = moment(this.endDate).format("DD/MM/YYYY");
                return date == "Invalid date" ? null : date;
            },
            // setter
            set: function (newValue) {
                if (!newValue) {
                    this.endDate = "";
                }
                let date = moment(this.endDate).format("DD/MM/YYYY");
                if (date == "Invalid date") {
                    return null;
                }
            },
        },
    },
    components: {
        ChangePwd,
        SubscriberDialog
    },
}
</script>

<style lang="scss" scoped></style>