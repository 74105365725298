<template>
    <v-dialog scrollable light v-model="checkpointDialog" persistent max-width="1000">
        <v-card>
            <v-card-title style="border-bottom: 1px solid #ccc;" class="headline px-2">
                <v-app-bar flat color="rgba(0, 0, 0, 0)">
                    <v-toolbar-title class="text-h5 pl-0">
                        Criar Checkpoint
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- <v-fab-transition> -->
                    <v-btn @click="toogleCheckpointDialog" class="mt-7" color="red" fab icon dark x-small absolute top
                        right>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <!-- </v-fab-transition> -->
                </v-app-bar>
            </v-card-title>
            <v-card-text>
                <v-card flat>
                    <v-card-text class="pa-0">
                        <v-tabs color="primaryNew" id="subscriberFormTab" v-model="tab" style="border-bottom: 1px solid #eee">
                            <v-tab> Dados da solicitação </v-tab>
                            <v-tab> Licenças </v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <v-form class="" ref="frmChkPoint" v-model="validTab1">
                                    <v-row class="pt-8 mb-6">
                                        <v-col class="px-15" cols="12" md="6">
                                            <v-text-field :disabled="$store.state.clientPermissions.subscriberJustView"
                                                v-model="formData.contact_name" :rules="$store.state.formRules.required"
                                                label="Nome do usuário" required></v-text-field>
                                        </v-col>
                                        <v-col class="px-15" cols="12" md="6">
                                            <v-text-field maxlength="8"
                                                :disabled="$store.state.clientPermissions.subscriberJustView"
                                                v-model="formData.batch" label="Número do lote"
                                                :rules="$store.state.formRules.required"></v-text-field>
                                        </v-col>
                                        <v-col class="px-15" cols="12" md="6">
                                            <v-text-field :disabled="$store.state.clientPermissions.subscriberJustView"
                                                v-model="formData.email" label="E-mail"
                                                :rules="$store.state.formRules.emailRules"></v-text-field>
                                        </v-col>
                                        <v-col class="px-15" cols="12" md="6">
                                            <div>
                                                <v-text-field-simplemask v-model="formData.phone" v-bind:label="'Telefone'"
                                                    v-bind:properties="{
                                                        rules: [
                                                            $store.state.formRules.phoneRules[0](
                                                                formData.phone
                                                            ),
                                                        ],
                                                        prefix: '',
                                                        suffix: '',
                                                        readonly: false,
                                                        disabled: false,
                                                        outlined: false,
                                                        clearable: false,
                                                        placeholder: '',
                                                    }" v-bind:options="{
    inputMask:
        (formData.phone ? formData.phone.length : '') < 11
            ? '(##) ####-#####'
            : '(##) # ####-####',
    outputMask: '############',
    empty: null,
    applyAfter: false,
    alphanumeric: true,
    lowerCase: false,
}" />
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-tab-item>
                            <v-tab-item>
                                <v-card flat>
                                    <v-card-text class="mt-5 pt-5">
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn @click="toogleLicenseFormDialog" v-bind="attrs" v-on="on"
                                                    class="mt-2" color="primaryNew" fab dark x-small absolute top right>
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                            </template>
                                            <span class="miniTtip">Nova licença</span>
                                        </v-tooltip>
                                        <LicenseList :licenses="licenses" :editLicense="editLicense"
                                            :confirmDelLicense="confirmDelLicense" />
                                        <LicenseForm :aplyLicense="aplyLicense" :editLicenseData="editLicenseData"
                                            :partnerId="formData.id" :toogleLicenseFormDialog="toogleLicenseFormDialog"
                                            :licenseFormDialog="licenseFormDialog" />
                                        <!-- :updateLicenseList="updateLicenseList" -->
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="toogleCheckpointDialog">
                    Fechar
                </v-btn>
                <!-- v-if="!$store.state.clientPermissions.subscriberJustView" -->
                <v-btn color="green" text @click="saveChaeckpoint">
                    Salvar </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import LicenseList from "./LicenseList.vue"
import LicenseForm from "./LicenseForm.vue"
const resetCheckpointForm = {
    contact_name: "",
    email: "",
    phone: "",
    batch: ""
}
export default {
    methods: {
        saveChaeckpoint() {
            if (!this.$refs.frmChkPoint.validate()) {
                return;
            }
            if (!this.licenses.length) {
                this.tab = 1
                let obj = {
                    message: `É necessário adicionar ao menos uma licença`,
                    title: "Aviso",
                    callback: (_) => {

                    },
                    type: "warning",
                    btConfirm: { name: "Ok", show: true },
                    btCancel: { name: "Não", show: false },
                };
                this.$store.commit("confirmMessage/showConfirmMessage", obj);
                return
            }
            let query = { ...this.formData }
            query.request_items = [...this.licenses]
            var obj = {
                url: "/api/v1/checkpoint/request/create",
                query: query,
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                if (!response) {
                    return;
                }
                let opts = {
                    message: "Checkpoint criado com sucesso",
                    type: "success",
                };
                this.$store.commit("showAlert", opts);
                this.toogleCheckpointDialog()
            })
        },
        aplyLicense(data) {
            this.licenses.push(data)
        },
        toogleLicenseFormDialog() {
            this.licenseFormDialog = !this.licenseFormDialog
        },
        editLicense(ind) {

        },
        confirmDelLicense(ind) {
            this.licenses.splice(ind, 1)
        },
        setEscClose() {
            console.log('close')
            let _this = this;
            document.onkeydown = function (evt) {
                evt = evt || window.event;
                var isEscape = false;
                if ("key" in evt) {
                    isEscape = (evt.key === "Escape" || evt.key === "Esc");
                } else {
                    isEscape = (evt.keyCode === 27);
                }
                if (isEscape) {
                    _this.toogleCheckpointDialog()
                    document.onkeydown = null;
                }
            };
        },
    },
    watch: {
        checkpointDialog(v) {
            if (!v) {
                document.onkeydown = null;
                this.licenses = []
                this.formData = { ...resetCheckpointForm }
                this.$refs.frmChkPoint.resetValidation();
                this.tab = 0
            } else {
                this.setEscClose()
            }
        }
    },
    data() {
        return {
            licenseFormDialog: false,
            licenses: [],
            editLicenseData: {},
            tab: 0,
            validTab1: true,
            formData: { ...resetCheckpointForm },
        }
    },
    props: {
        toogleCheckpointDialog: Function,
        checkpointDialog: Boolean
    },
    components: {
        LicenseList, LicenseForm
    },
}
</script>

<style lang="scss" scoped></style>