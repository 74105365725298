<template>
  <v-dialog v-model="resetPwdDialog" max-width="400px" persistent>
    <v-card>
      <v-card-title style="border-bottom: 1px solid #ccc;" class="mb-5 headline px-2 pt-2 pb-0">
        <v-app-bar flat color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h5 pl-0">
            Alterar senha
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            @click="close"
            class="mt-7"
            color="red"
            fab
            icon
            dark
            x-small
            absolute
            top
            right
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
      </v-card-title>
      <v-card-text>
        <v-form ref="changePwdForm" v-model="validForm" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="formData.password_new"
                  label="Nova senha"
                  :rules="[rulesPass.required, rulesPass.min]"
                  required
                  :append-icon="show2 ? 'visibility' : 'visibility_off'"
                  :type="show2 ? 'text' : 'password'"
                  @click:append="show2 = !show2"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="formData.confirmPwd"
                  label="Confirme a nova senha"
                  :rules="[rulesPass.matchPass]"
                  required
                  :append-icon="show3 ? 'visibility' : 'visibility_off'"
                  :type="show3 ? 'text' : 'password'"
                  @click:append="show3 = !show3"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions
        class="px-0 mx-3"
        style="border-top: 1px solid rgb(220, 220, 220)"
      >
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="close"> Fechar </v-btn>
        <v-btn color="green" text @click="saveNewPass"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  watch: {
    resetPwdDialog(v) {
      this.$refs.changePwdForm.resetValidation();
      if (!v) {
        this.show2 = false;
        this.show3 = false;
        this.formData.password_new = "";
        this.formData.confirmPwd = "";
      }
    },
  },
  data: function () {
    let fixData = {
      show1: false,
      show2: false,
      show3: false,
      formData: {
        password_new: null,
        confirmPwd: null,
      },
      validForm: true,
      rulesPass: {
        required: (value) => !!value || "Não pode ser vazio.",
        min: (v) => (v && v.length >= 8) || "Mínimo 8 caracters",
        matchPass: (v) =>
          (v && v === this.formData.password_new) || "A senha não confere",
      },
    };
    return fixData;
  },
  mounted() {
    //this.formData.user_id = JSON.parse(localStorage.getItem("userData")).user_id;
    //console.log('8989898989',this.user_id);
  },
  props: {
    subscriberId: Number,
    resetPwdDialog: Boolean,
    toogleChangePwdDialog: Function,
  },
  methods: {
    close() {
      this.toogleChangePwdDialog();
    },
    saveNewPass() {
      if (!this.$refs.changePwdForm.validate()) {
        return;
      }
      const _this = this;

      let obj = {
        url: "/api/v1/subscriptionAPI/subscriber/changePassword",
        query: {
          id: this.subscriberId,
          password: this.formData.password_new,
        },
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        if (!response) {
          return;
        }
        let opts = {
          message: `Senha alterada com sucesso`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
        this.close();
      });
    },
  },
};
</script>

<style>
</style>
