<template>
    <div class="pb-15" style="max-width: 1600px; margin: auto; position: relative;">
        <v-row no-gutters class="px-1 pt-0">
            <v-col cols="12" md="12" class="pl-2 pr-3 mt-12 mb-8">
                <h2 class="listTitles font-weight-medium">Minha lista de checkpoints</h2>
            </v-col>
            <v-col cols="12" md="3" class="pl-2 pr-3">
                <v-text-field prepend-icon="filter_alt" :disabled="$store.state.clientPermissions.subscriberJustView"
                    v-model="batch" label="Número do lote"></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="mt-3">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" @click="getCheckpointsByFilters(true)" class="ma-2" x-small fab
                            color="primary">
                            <v-icon small>search</v-icon>
                        </v-btn>
                    </template>
                    <span>Pesquisar</span>
                </v-tooltip>
            </v-col>
            <v-col cols="12" md="12" class="pt-0 pl-2 pr-3 mt-3 text-right">
                <v-btn class="ma-2 py-2" color="success" @click="toogleCheckpointDialog">
                    <span class="noIconPlus">+</span> Criar checkpoint
                </v-btn>
            </v-col>
            <v-col cols="12" md="12" class="mt-5">
                <v-data-table :class="[checkpoints ? 'hideCol' : '', 'elevation-1 thBorderBold']" id="checkpointTable"
                    :headers="headers" :items="checkpoints" :items-per-page="5"
                    no-data-text="Clique acima no botão pesquisar">
                    <template v-slot:item.status="{ item }">

                        <div :class="[setStatusIcon(item.status)[1] + '--text']">{{ item.status }}<v-icon size="21"
                                class="ml-2" :color="setStatusIcon(item.status)[1]">{{ setStatusIcon(item.status)[0]
                                }}</v-icon>
                        </div>


                    </template>

                    <template v-slot:item.created_at="{ item }">
                        {{ item.issue_date | formatDate }}
                    </template>
                    <template v-slot:item.request_items="{ item }">
                        {{ item.request_items.length }} licença(s)
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <div>
                            <v-btn color="primaryNew" @click="showBatchDetails(item)" outlined rounded x-small>
                                + Detalhes
                            </v-btn>
                            <v-btn v-if="item.worksheet_process_log.length" class="ml-3" color="primaryNew"
                                @click="showLogDetails(item)" outlined rounded x-small>
                                Visualizar logs
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <CheckpointDialog :toogleCheckpointDialog="toogleCheckpointDialog" :checkpointDialog="checkpointDialog" />
        <ShowLicensesDialog :toogleLicenseDialog="toogleLicenseDialog" :licenseDialog="licenseDialog"
            :licenses="licenses" />
        <LogDetails :toogleLogDialog="toogleLogDialog" :logDialog="logDialog" :stringMessage="stringMessage"
            :jsonMessage="jsonMessage" />
    </div>
</template>

<script>
import CheckpointDialog from "../../../../components/clients/subscriber/checkpoint/CheckpointDialog.vue"
import ShowLicensesDialog from "../../../../components/clients/subscriber/checkpoint/ShowLicensesDialog.vue"
import LogDetails from "../../../../components/clients/subscriber/checkpoint/LogDetails.vue"
export default {
    methods: {
        showLogDetails(data) {
            let formatJSON = JSON.parse(data.worksheet_process_log[0].big_message)
            this.jsonMessage = JSON.stringify(formatJSON, null, 4)
            this.stringMessage = data.worksheet_process_log[0].message
            this.toogleLogDialog()
        },
        toogleLogDialog() {
            this.logDialog = !this.logDialog
        },
        setStatusIcon(status) {
            switch (status) {
                case "Liberado":
                    return ["playlist_add_check", "green"];
                case "Reprovado":
                    return ["remove_circle_outline", "red"];
                case "Gerando":
                    return ["history_toggle_off", "primary"];
                default:
                    return ["rule", "warning"];
            }
        },
        toogleLicenseDialog() {
            this.licenseDialog = !this.licenseDialog
        },
        showBatchDetails(data) {
            this.licenses = data.request_items
            this.toogleLicenseDialog()
        },
        toogleCheckpointDialog() {
            this.checkpointDialog = !this.checkpointDialog
        },
        getCheckpoints(data, loader) {
            var obj = {
                // noLoader: nLoad == "nLoad" ? true : false,
                url: data ? "api/v1/checkpoint/request/getByBatch" : "/api/v1/checkpoint/request/getAll",
                query: data,
                noLoader: !loader ? true : false,
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                // console.log(response);
                this.checkpoints = [...response.data];
            });
        },
        getCheckpointsByFilters(clearTime) {
            let query = null
            if (this.batch) {
                query = {
                    batch: this.batch
                }
            }
            // console.log('rota', this.$route.name)
            // if (this.$route.name != 'subscriber') {
            //     clearTimeout(this.updateCheckpointList)
            //     return
            // }
            this.getCheckpoints(query, clearTime)
            if (clearTime) {
                clearTimeout(this.updateCheckpointList)
            }
            this.updateCheckpointList = setTimeout(() => {
                this.getCheckpointsByFilters()
            }, 30000);
        },
    },
    watch: {
        checkpointHomeDialog(v) {
            if (!v) {
                clearTimeout(this.updateCheckpointList)
            } else {
                this.getCheckpointsByFilters(true)
            }
        }
    },
    data() {
        return {
            logDialog: false,
            stringMessage: "",
            jsonMessage: "",
            licenses: [],
            licenseDialog: false,
            updateCheckpointList: null,
            batch: "",
            checkpointDialog: false,
            checkpoints: [],
            headers: [
                {
                    text: "Status",
                    value: "status",
                    align: "start",
                    sortable: false,
                },
                {
                    text: "Número do lote",
                    value: "batch",
                    align: "start",
                    sortable: false,
                },

                {
                    text: "Qtd. de itens",
                    value: "request_items",
                    align: "start",
                    sortable: false,
                },
                {
                    text: "Data da solicitação",
                    value: "created_at",
                    sortable: false,
                    align: "center",
                },
                { text: "Ações", value: "actions", sortable: false },
            ],
        }
    },
    mounted() {
        this.getCheckpointsByFilters(true)
    },
    props: {
        checkpointHomeDialog: Boolean
    },
    components: {
        CheckpointDialog,
        ShowLicensesDialog,
        LogDetails
    },
}
</script>

<style lang="scss" scoped></style>