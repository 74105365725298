var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 mb-4",attrs:{"headers":_vm.headers,"items":_vm.contracteds,"items-per-page":10,"no-data-text":"Ainda não existem pacotes adicionais nesta assinatura."},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" R$ "+_vm._s(_vm._f("formatPrice")(item.price))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btMicro px-1",attrs:{"disabled":_vm.$store.state.clientPermissions.subscriberJustView,"x-small":"","color":"red","dark":""},on:{"click":function($event){return _vm.confirmRemovePac(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","x-small":""}},[_vm._v(" mdi-delete-forever ")])],1)]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v("Excluir pacote")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }